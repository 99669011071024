import {
  Configuration,
  DictionaryApiFactory,
  InvoicesApiFactory,
  OrdersApiFactory,
  SteppingsApiFactory,
  StripeApiFactory,
  UsersApiFactory,
} from '@/castapi';
import { config } from '../config';

export const getUsersApi = (accessToken?: string) => {
  const configData = new Configuration({
    basePath: config.apiUrl,
  });
  if (accessToken) {
    configData.accessToken = accessToken;
  }
  return UsersApiFactory(configData);
};

export const getSteppingsApi = (accessToken?: string) => {
  const configData = new Configuration({
    basePath: config.apiUrl,
  });
  if (accessToken) {
    configData.accessToken = accessToken;
  }
  return SteppingsApiFactory(configData);
};

export const getOrdersApi = (accessToken?: string) => {
  const configData = new Configuration({
    basePath: config.apiUrl,
  });
  if (accessToken) {
    configData.accessToken = accessToken;
  }
  return OrdersApiFactory(configData);
};

export const getStripeApi = (accessToken?: string) => {
  const configData = new Configuration({
    basePath: config.apiUrl,
  });
  if (accessToken) {
    configData.accessToken = accessToken;
  }
  return StripeApiFactory(configData);
};

export const getInvoicesApi = (accessToken?: string) => {
  const configData = new Configuration({
    basePath: config.apiUrl,
  });
  if (accessToken) {
    configData.accessToken = accessToken;
  }
  return InvoicesApiFactory(configData);
};

export const getDictionaryApi = (accessToken?: string) => {
  const configData = new Configuration({
    basePath: config.apiUrl,
  });
  if (accessToken) {
    configData.accessToken = accessToken;
  }
  return DictionaryApiFactory(configData);
};
