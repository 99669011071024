import {
  CloudProductInfoDto,
  Configuration,
  DongleActivityDto,
  DongleLicenseDto,
  DongleReauthCodeDto,
  DonglesApiFactory,
  DongleUpgradeDto,
  DongleUsersInfoDto,
  InsertDongleByAdminDto,
  ProductInfoDto,
  RenewLeaseDto,
  RenewMembershipDto,
  SteppingDto,
  SteppingsApiFactory,
  UpgradeTypeDto,
} from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { getErrorMessage, ISearchParams } from '@/castapi/helpers';
import { AppLogger } from '@/logger';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminDongles state');

interface IAdminDonglesState {
  dongles: DongleUsersInfoDto[];
  availableDongles: ProductInfoDto[];
  availableDonglesLoading: boolean;
  availableDonglesLoadError: null | string;
  organizationAndUserDongles: ProductInfoDto[] | null;
  steppingsList: SteppingDto[];
  steppingsLoading: boolean;
  steppingsLoadError: null | string;
  allDonglesLoading: boolean;
  allDonglesLoadError: null | string;
  organizationAndUserDonglesLoading: boolean;
  organizationAndUserDonglesLoadError: null | string;
  insertDongleError: null | string;
  assignDongleError: null | string;
  dongleTransferInProgress: boolean;
  dongleTransferError: null | string;
  dongleDeleteInProgress: boolean;
  dongleDeleteError: null | string;
  statusChangeError: null | string;
  dongleChanging: boolean;
  renewCode: null | string;
  upgradeCode: null | string;
  upgradeTypes: UpgradeTypeDto[];
  currentDongle: null | ProductInfoDto | CloudProductInfoDto;
  currentDongleChanging: boolean;
  currentDongleChangeError: null | string;
  dongleActivitiesLoading: boolean;
  dongleActivitiesLoadError: null | string;
  dongleActivities: DongleActivityDto[];
  dongleLicensesLoading: boolean;
  dongleLicensesLoadError: null | string;
  dongleLicenses: DongleLicenseDto[];
  isRenewUpgrade: true;
  generatingDongleUpgradeCode: boolean;
  generateMembershipLoading: boolean;
  userDongles: [];
  userDonglesLoading: boolean;
  userDonglesLoadError: null | string;
  dongleReauthData: null | DongleReauthCodeDto;
  dongleReauthDataLoading: boolean;
  dongleReauthDataLoadError: null | string;
  cheatCounterResetError: null | string;
  renewUpgradeCodeGenerationError: null | string;
}

const initialState = (): IAdminDonglesState => ({
  dongles: [],
  availableDongles: [],
  availableDonglesLoading: false,
  availableDonglesLoadError: null,
  organizationAndUserDongles: [],
  steppingsList: [],
  steppingsLoading: false,
  steppingsLoadError: null,
  allDonglesLoading: false,
  allDonglesLoadError: null,
  organizationAndUserDonglesLoading: false,
  organizationAndUserDonglesLoadError: null,
  insertDongleError: null,
  generatingDongleUpgradeCode: false,
  generateMembershipLoading: false,
  assignDongleError: null,
  dongleTransferInProgress: false,
  dongleTransferError: null,
  dongleDeleteInProgress: false,
  dongleDeleteError: null,
  statusChangeError: null,
  dongleChanging: false,
  renewCode: null,
  upgradeCode: null,
  upgradeTypes: [],
  currentDongle: null,
  currentDongleChanging: false,
  currentDongleChangeError: null,
  dongleActivitiesLoading: false,
  dongleActivitiesLoadError: null,
  dongleActivities: [],
  isRenewUpgrade: true,
  userDongles: [],
  userDonglesLoading: false,
  userDonglesLoadError: null,
  dongleReauthData: null,
  dongleReauthDataLoading: false,
  dongleReauthDataLoadError: null,
  cheatCounterResetError: null,
  renewUpgradeCodeGenerationError: null,
  dongleLicenses: [],
  dongleLicensesLoading: false,
  dongleLicensesLoadError: null,
});

const getDonglesApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DonglesApiFactory(config);
};

const getSteppingsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return SteppingsApiFactory(config);
};

interface SerialData {
  code: string;
  serialNumber: string;
}

type ActionParams = IActionParams & { state: IAdminDonglesState };

// noinspection JSUnusedGlobalSymbols
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SELECT_DONGLE(state: IAdminDonglesState, dongle: ProductInfoDto | CloudProductInfoDto): void {
      state.currentDongle = dongle;
    },

    STEPPINGS_LOADING(state: IAdminDonglesState): void {
      state.steppingsLoadError = null;
      state.steppingsLoading = true;
    },

    STEPPINGS_LOADED(state: IAdminDonglesState, payload: SteppingDto[]): void {
      state.steppingsList = payload;
      state.steppingsLoadError = null;
    },

    STEPPINGS_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.steppingsLoadError = getErrorMessage(error);
      state.steppingsLoading = false;
    },

    DONGLES_LOADING(state: IAdminDonglesState): void {
      state.allDonglesLoadError = null;
      state.allDonglesLoading = true;
    },

    DONGLES_LOADED(state: IAdminDonglesState, payload: DongleUsersInfoDto[]): void {
      state.dongles = payload;
      state.allDonglesLoading = false;
    },

    DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.allDonglesLoadError = getErrorMessage(error);
      state.allDonglesLoading = false;
    },

    AVAILABLE_DONGLES_LOADING(state: IAdminDonglesState): void {
      state.availableDonglesLoadError = null;
      state.availableDonglesLoading = true;
    },

    AVAILABLE_DONGLES_LOADED(state: IAdminDonglesState, payload: ProductInfoDto[]): void {
      state.availableDongles = payload;
      state.availableDonglesLoading = false;
    },

    AVAILABLE_DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.availableDonglesLoadError = getErrorMessage(error);
      state.availableDonglesLoading = false;
    },

    ORGANIZATION_DONGLES_LOADING(state: IAdminDonglesState): void {
      state.organizationAndUserDonglesLoadError = null;
      state.organizationAndUserDonglesLoading = true;
    },

    ORGANIZATION_DONGLES_LOADED(state: IAdminDonglesState, payload: ProductInfoDto[]): void {
      state.organizationAndUserDongles = payload;
      state.organizationAndUserDonglesLoading = false;
    },

    ORGANIZATION_DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.organizationAndUserDonglesLoadError = getErrorMessage(error);
      state.organizationAndUserDonglesLoading = false;
    },
    //---

    USER_DONGLES_LOADING(state: IAdminDonglesState): void {
      state.userDonglesLoadError = null;
      state.userDonglesLoading = true;
    },

    USER_DONGLES_LOADED(state: IAdminDonglesState, payload: ProductInfoDto[]): void {
      state.organizationAndUserDongles = payload;
      state.userDonglesLoading = false;
    },

    USER_DONGLES_LOAD_ERROR(state: IAdminDonglesState, error: Error): void {
      state.userDonglesLoadError = getErrorMessage(error);
      state.userDonglesLoading = false;
    },
    //---
    INSERT_DONGLE_ERROR(state: IAdminDonglesState, error: Error): void {
      state.insertDongleError = getErrorMessage(error);
    },

    ASSIGN_DONGLE_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.assignDongleError = getErrorMessage(payload);
    },

    DONGLE_TRANSFER_IN_PROGRESS(state: IAdminDonglesState): void {
      state.dongleTransferError = null;
      state.dongleTransferInProgress = true;
    },

    DONGLE_TRANSFER_SUCCESS(state: IAdminDonglesState): void {
      state.dongleTransferInProgress = false;
    },

    DONGLE_TRANSFER_ERROR(state: IAdminDonglesState, error: Error): void {
      state.dongleTransferError = getErrorMessage(error);
      state.dongleTransferInProgress = false;
    },

    DONGLE_DELETE_IN_PROGRESS(state: IAdminDonglesState): void {
      state.dongleDeleteError = null;
      state.dongleDeleteInProgress = true;
    },

    DONGLE_DELETE_SUCCESS(state: IAdminDonglesState): void {
      state.dongleDeleteInProgress = false;
    },

    DONGLE_DELETE_ERROR(state: IAdminDonglesState, error: Error): void {
      state.dongleDeleteInProgress = false;
      state.dongleDeleteError = getErrorMessage(error);
    },

    CHANGE_DONGLE_STATUS_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.statusChangeError = getErrorMessage(payload);
    },

    CHEAT_COUNTER_RESET_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.cheatCounterResetError = getErrorMessage(payload);
    },

    DONGLE_CHANGING(state: IAdminDonglesState): void {
      state.assignDongleError = null;
      state.statusChangeError = null;
      state.cheatCounterResetError = null;
      state.renewUpgradeCodeGenerationError = null;
      state.dongleChanging = true;
    },

    GENERATING_UPGRADE_CODE(state: IAdminDonglesState): void {
      state.generatingDongleUpgradeCode = true;
    },

    GENERATED_UPGRADE_CODE(state: IAdminDonglesState): void {
      state.generatingDongleUpgradeCode = false;
    },

    GENERATING_MEMBERSHIP(state: IAdminDonglesState): void {
      state.generateMembershipLoading = true;
    },

    MEMBERSHIP_GENERATED(state: IAdminDonglesState): void {
      state.generateMembershipLoading = false;
    },

    DONGLE_CHANGED(state: IAdminDonglesState): void {
      state.dongleChanging = false;
    },

    GET_RENEW_MEMBERSHIP_CODE(state: IAdminDonglesState, data: SerialData): void {
      state.renewCode = data.code;
      if (!state.organizationAndUserDongles) {
        throw new Error('organizationAndUserDongles is not defined');
      }
      const dongleIndex = state.organizationAndUserDongles.findIndex(d => d.serialNumber === data.serialNumber);
      state.organizationAndUserDongles[dongleIndex].renewCode = data.code;
    },

    GET_UPGRADE_CODE(state: IAdminDonglesState, data: SerialData): void {
      state.upgradeCode = data.code;
      if (!state.organizationAndUserDongles) {
        throw new Error('organizationAndUserDongles is not defined');
      }
      const dongleIndex = state.organizationAndUserDongles.findIndex(d => d.serialNumber === data.serialNumber);
      state.organizationAndUserDongles[dongleIndex].upgradeCode = data.code;
    },

    GET_UPGRADE_TYPES(state: IAdminDonglesState, payload: []): void {
      state.upgradeTypes = payload;
    },

    DONGLE_ACTIVITIES_LOADING(state: IAdminDonglesState): void {
      state.dongleActivities = [];
      state.dongleActivitiesLoadError = null;
      state.dongleActivitiesLoading = true;
    },

    DONGLE_ACTIVITIES_LOADED(state: IAdminDonglesState, activities: DongleActivityDto[]): void {
      state.dongleActivities = activities;
      state.dongleActivitiesLoading = false;
    },

    DONGLE_ACTIVITIES_LOAD_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleActivitiesLoading = false;
      state.dongleActivitiesLoadError = getErrorMessage(payload);
    },

    DONGLE_LICENSES_LOADING(state: IAdminDonglesState): void {
      state.dongleLicenses = [];
      state.dongleLicensesLoadError = null;
      state.dongleLicensesLoading = true;
    },

    DONGLE_LICENSES_LOADED(state: IAdminDonglesState, activities: DongleLicenseDto[]): void {
      state.dongleLicenses = activities;
      state.dongleLicensesLoading = false;
    },

    DONGLE_LICENSES_LOAD_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleLicensesLoading = false;
      state.dongleLicensesLoadError = getErrorMessage(payload);
    },

    DONGLE_REAUTH_DATA_LOADING(state: IAdminDonglesState): void {
      state.dongleReauthData = null;
      state.dongleReauthDataLoadError = null;
      state.dongleReauthDataLoading = true;
    },

    DONGLE_REAUTH_DATA_LOADED(state: IAdminDonglesState, data: DongleReauthCodeDto): void {
      state.dongleReauthData = data;
      state.dongleReauthDataLoading = false;
    },

    DONGLE_REAUTH_DATA_LOAD_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleReauthDataLoading = false;
      state.dongleReauthDataLoadError = getErrorMessage(payload);
    },

    DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR(state: IAdminDonglesState, payload: Error): void {
      state.dongleChanging = false;
      state.renewUpgradeCodeGenerationError = getErrorMessage(payload);
    },

    RESET_STATE(state: IAdminDonglesState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },

    CLEAR_ORGANIZATION_DONGLES(state: IAdminDonglesState): void {
      state.organizationAndUserDongles = null;
    },
  },
  actions: {
    async loadDongles({ commit, rootGetters }: ActionParams, searchParams: ISearchParams): Promise<void> {
      const { limit, offset, searchText, sortBy, sortDesc } = searchParams || {};
      commit('DONGLES_LOADING', { limit, offset, searchText });
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAll(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('DONGLES_LOADED', response.data);
      } catch (error) {
        commit('DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async loadSteppings({ state, commit, rootGetters }: ActionParams): Promise<void> {
      if (state.steppingsList.length) {
        return;
      }
      try {
        // commit('RESET_STATE');
        commit('STEPPINGS_LOADING');
        const response = await getSteppingsApi(rootGetters['login/accessToken']).steppingControllerFindAll();
        commit('STEPPINGS_LOADED', response.data);
      } catch (error) {
        commit('STEPPINGS_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error);
      }
    },

    async loadAvailableDongles(
      { commit, rootGetters, state }: ActionParams,
      {
        offset,
        limit,
        searchText,
        productLineId,
        productLevel,
        licenseTypeId,
      }: ISearchParams & {
        productLineId: number;
        productLevel: number;
        licenseTypeId: number;
      },
    ): Promise<void> {
      try {
        // commit('RESET_STATE');
        commit('AVAILABLE_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllAvailable(
          limit,
          offset,
          searchText,
          undefined,
          undefined,
          productLineId,
          productLevel,
          licenseTypeId,
        );
        commit('AVAILABLE_DONGLES_LOADED', [...(offset ? state.availableDongles : []), ...response.data]);
      } catch (error) {
        commit('AVAILABLE_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadAvailableDongles', error);
      }
    },

    async loadOrganizationDongles({ commit, rootGetters }: ActionParams, data: number): Promise<void> {
      try {
        // commit('RESET_STATE');
        commit('ORGANIZATION_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByOrganization(
          data,
        );
        commit('ORGANIZATION_DONGLES_LOADED', response.data);
      } catch (error) {
        commit('ORGANIZATION_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizationDongles', error, { data });
      }
    },

    async loadUserDongles({ commit, rootGetters }: ActionParams, data: number): Promise<void> {
      try {
        // commit('RESET_STATE');
        commit('USER_DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByUser(data);
        commit('USER_DONGLES_LOADED', response.data);
      } catch (error) {
        commit('USER_DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadUserDongles', error, { data });
      }
    },

    async loadVisibleDongles({ dispatch, rootGetters }: ActionParams, fromPage: string): Promise<void> {
      if (fromPage === 'organizations') {
        const organizationId = rootGetters['adminOrganizations/currentOrganizationId'];
        await dispatch('loadOrganizationDongles', organizationId);
      } else if (fromPage === 'user') {
        const userId = rootGetters['adminUsers/editingUserId'];
        await dispatch('loadUserDongles', userId);
      }
    },

    async insertDongle({ commit, rootGetters }: ActionParams, data: InsertDongleByAdminDto): Promise<void> {
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerInsertDongleByAdmin(data);
      } catch (error) {
        commit('INSERT_DONGLE_ERROR', error);
        logger.captureStoreError('insertDongle', error, { data });
      }
    },

    async assignDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      {
        dongleId,
        userId,
        fromPage,
      }: {
        dongleId: number;
        userId: number;
        fromPage: string;
      },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_CHANGING');
        await getDonglesApi(accessToken).donglesControllerAssignDongle({ dongleId, userId });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('ASSIGN_DONGLE_ERROR', error);
        logger.captureStoreError('assignDongle', error, { dongleId, userId, fromPage });
      }
    },

    async unAssignDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, fromPage }: { dongleId: number; fromPage: string },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_CHANGING');
        await getDonglesApi(accessToken).donglesControllerUnAssignDongle({ dongleId });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('ASSIGN_DONGLE_ERROR', error);
        logger.captureStoreError('unAssignDongle', error, { dongleId, fromPage });
      }
    },

    async transferDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, email, fromPage }: { dongleId: number; email: string; fromPage: string },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_TRANSFER_IN_PROGRESS');
        await getDonglesApi(accessToken).donglesControllerTransferDongle({ dongleId, email });
        commit('DONGLE_TRANSFER_SUCCESS');
        await dispatch('loadVisibleDongles', fromPage);
      } catch (error) {
        commit('DONGLE_TRANSFER_ERROR', error);
        logger.captureStoreError('transferDongle', error, { dongleId, fromPage });
      }
    },

    async removeDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, fromPage }: { dongleId: number; fromPage: string },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DONGLE_DELETE_IN_PROGRESS');
        await getDonglesApi(accessToken).donglesControllerRemoveDongle({ dongleId });
        commit('DONGLE_DELETE_SUCCESS');
        await dispatch('loadVisibleDongles', fromPage);
      } catch (error) {
        commit('DONGLE_DELETE_ERROR', error);
        logger.captureStoreError('removeDongle', error, { dongleId, fromPage });
      }
    },

    async changeDongleStatus(
      { commit, dispatch, rootGetters }: ActionParams,
      {
        isLoaned,
        isRetired,
        notes,
        dongleId,
        fromPage,
      }: {
        isLoaned: boolean;
        isRetired: boolean;
        notes: string;
        dongleId: number;
        fromPage: string;
      },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerChangeDongleStatus({
          isLoaned,
          isRetired,
          notes,
          dongleId,
        });
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('CHANGE_DONGLE_STATUS_ERROR', error);
        logger.captureStoreError('changeDongleStatus', error, { isLoaned, isRetired, notes, dongleId, fromPage });
      }
    },

    async getRenewMembershipCode(
      { commit, dispatch, rootGetters }: ActionParams,
      {
        data,
        fromPage,
      }: {
        data: RenewMembershipDto;
        fromPage: string;
      },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerRenewMembership(data);
        commit('DONGLE_CHANGED');
        commit('GET_RENEW_MEMBERSHIP_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getRenewMembershipCode', error, { data, fromPage });
      }
    },

    async getRenewLeaseCode(
      { commit, dispatch, rootGetters }: ActionParams,
      { data, fromPage }: { data: RenewLeaseDto; fromPage: string },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      commit('GENERATING_MEMBERSHIP');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerRenewLease(data);
        commit('DONGLE_CHANGED');
        commit('MEMBERSHIP_GENERATED');
        commit('GET_RENEW_MEMBERSHIP_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getRenewLeaseCode', error, { data, fromPage });
      }
    },

    async getUpgradeCode(
      { commit, dispatch, rootGetters }: ActionParams,
      { data, fromPage }: { data: DongleUpgradeDto; fromPage: string },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      commit('GENERATING_UPGRADE_CODE');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetUpgradeCode(data);
        commit('DONGLE_CHANGED');
        commit('GENERATED_UPGRADE_CODE');
        commit('GET_UPGRADE_CODE', { code: response.data, serialNumber: data.serialNumber });
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', data.dongleId);
      } catch (error) {
        commit('DONGLE_RENEW_UPGRADE_CODE_GENERATION_ERROR', error);
        logger.captureStoreError('getUpgradeCode', error, { data, fromPage });
      }
    },

    async getUpgradeTypes({ commit, rootGetters }: ActionParams, data: number): Promise<void> {
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetUpgradeTypes(data);
        commit('GET_UPGRADE_TYPES', response.data);
      } catch (error) {
        logger.captureStoreError('getUpgradeTypes', error, { data });
      }
    },

    async selectDongle(
      { commit, dispatch }: ActionParams,
      dongle: ProductInfoDto | CloudProductInfoDto,
    ): Promise<void> {
      commit('SELECT_DONGLE', dongle);
      if ((dongle as ProductInfoDto).dongleId) {
        const dongleId = (dongle as ProductInfoDto).dongleId;
        await dispatch('loadDongleActivities', dongleId);
        await dispatch('loadDongleLicenses', dongleId);
        await dispatch('loadDongleReauthData', dongleId);
      } else {
        // TODO: Implement for CloudProductInfoDto
      }
    },

    async loadDongleActivities({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_ACTIVITIES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetDongleActivities(
          dongleId,
        );
        commit('DONGLE_ACTIVITIES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_ACTIVITIES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleActivities', error, { dongleId });
      }
    },

    async loadDongleLicenses({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_LICENSES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetLearnDongleLicenses(
          dongleId,
        );
        commit('DONGLE_LICENSES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_LICENSES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleLicenses', error, { dongleId });
      }
    },

    async loadDongleReauthData({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_REAUTH_DATA_LOADING');
      try {
        const response = await getDonglesApi(
          rootGetters['login/accessToken'],
        ).donglesControllerGetDongleLatestReauthCode(dongleId);
        commit('DONGLE_REAUTH_DATA_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_REAUTH_DATA_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleReauthData', error, { dongleId });
      }
    },

    async resetDongleCheatCounter(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, fromPage }: { dongleId: number; fromPage: string },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerResetDongleCheatCounter(dongleId);
        commit('DONGLE_CHANGED');
        await dispatch('loadVisibleDongles', fromPage);
        await dispatch('loadDongleActivities', dongleId);
      } catch (error) {
        commit('CHEAT_COUNTER_RESET_ERROR', error);
        logger.captureStoreError('resetDongleCheatCounter', error, { dongleId, fromPage });
      }
    },

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    dongles: (state: IAdminDonglesState): DongleUsersInfoDto[] => state.dongles,
    steppingsList: (state: IAdminDonglesState): SteppingDto[] => state.steppingsList,
    availableDongles: (state: IAdminDonglesState): ProductInfoDto[] => state.availableDongles,
    organizationDongles: (state: IAdminDonglesState): ProductInfoDto[] | null => state.organizationAndUserDongles,
    allDonglesLoading: (state: IAdminDonglesState): boolean => state.allDonglesLoading,
    allDonglesLoadError: (state: IAdminDonglesState): string | null => state.allDonglesLoadError,
    availableDonglesLoading: (state: IAdminDonglesState): boolean => state.availableDonglesLoading,
    availableDonglesLoadError: (state: IAdminDonglesState): string | null => state.availableDonglesLoadError,
    organizationDonglesLoading: (state: IAdminDonglesState): boolean => state.organizationAndUserDonglesLoading,
    organizationDonglesLoadError: (state: IAdminDonglesState): string | null =>
      state.organizationAndUserDonglesLoadError,
    insertDongleError: (state: IAdminDonglesState): string | null => state.insertDongleError,
    // dongleDeleteInProgress: (state: IAdminDonglesState) => state.dongleDeleteInProgress,
    // dongleDeleteError: (state: IAdminDonglesState) => state.dongleDeleteError,
    dongleTransferInProgress: (state: IAdminDonglesState): boolean => state.dongleTransferInProgress,
    dongleTransferError: (state: IAdminDonglesState): string | null => state.dongleTransferError,
    dongleChanging: (state: IAdminDonglesState): boolean => state.dongleChanging,
    generatingDongleUpgradeCode: (state: IAdminDonglesState): boolean => state.generatingDongleUpgradeCode,
    generateMembershipLoading: (state: IAdminDonglesState): boolean => state.generateMembershipLoading,
    statusChangeError: (state: IAdminDonglesState): string | null => state.statusChangeError,
    upgradeTypes: (state: IAdminDonglesState): UpgradeTypeDto[] => state.upgradeTypes,
    // currentDongle: (state: IAdminDonglesState) => state.currentDongle,
    currentDongleId: (state: IAdminDonglesState): number => {
      if ((state.currentDongle as ProductInfoDto).dongleId) {
        return (state.currentDongle as ProductInfoDto).dongleId;
      }
      return (state.currentDongle as CloudProductInfoDto).cloudProductId;
    },
    dongleActivities: (state: IAdminDonglesState): DongleActivityDto[] => state.dongleActivities,
    dongleActivitiesLoading: (state: IAdminDonglesState): boolean => state.dongleActivitiesLoading,
    dongleActivitiesLoadError: (state: IAdminDonglesState): string | null => state.dongleActivitiesLoadError,
    userDongles: (state: IAdminDonglesState): ProductInfoDto[] | null => state.organizationAndUserDongles,
    userDonglesLoading: (state: IAdminDonglesState): boolean => state.userDonglesLoading,
    userDonglesLoadError: (state: IAdminDonglesState): string | null => state.userDonglesLoadError,
    dongleReauthData: (state: IAdminDonglesState): DongleReauthCodeDto | null => state.dongleReauthData,
    dongleReauthDataLoading: (state: IAdminDonglesState): boolean => state.dongleReauthDataLoading,
    dongleReauthDataLoadError: (state: IAdminDonglesState): string | null => state.dongleReauthDataLoadError,
    cheatCounterResetError: (state: IAdminDonglesState): string | null => state.cheatCounterResetError,
    renewUpgradeCodeGenerationError: (state: IAdminDonglesState): string | null =>
      state.renewUpgradeCodeGenerationError,
    dongleDeleteError: (state: IAdminDonglesState): string | null => state.dongleDeleteError,
    dongleLicenses: (state: IAdminDonglesState): DongleLicenseDto[] => state.dongleLicenses,
  },
};
