import { Configuration, DownloadsApiFactory, FileDto, ReleaseForDownloadsDto } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { downloadFileUsingUrl } from '@/shared/functions';
import { getErrorMessage } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('customerDownloads state');

export interface FilesItem extends FileDto {
  image: string;
  availability: number;
}

interface IDownloadsState {
  releases: ReleaseForDownloadsDto[];
  releasesLoading: boolean;
  releasesLoadError: null | string;
  files: FileDto[];
  filesLoading: boolean;
  filesLoadError: null | string;
  selectedRelease: FilesItem | null;
}

const initialState = (): IDownloadsState => ({
  releases: [],
  files: [],
  releasesLoading: false,
  releasesLoadError: null,
  filesLoading: false,
  filesLoadError: null,
  selectedRelease: null,
});

const getDownloadsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DownloadsApiFactory(config);
};

type ActionParams = IActionParams & { state: IDownloadsState };

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RELEASES_LOADING(state: IDownloadsState): void {
      state.releasesLoadError = null;
      state.releasesLoading = true;
    },
    RELEASES_LOADED(state: IDownloadsState, payload: ReleaseForDownloadsDto[]): void {
      state.releases = payload;
      state.releasesLoading = false;
    },
    RELEASES_LOAD_ERROR(state: IDownloadsState, error: Error): void {
      state.releasesLoadError = getErrorMessage(error);
      state.releasesLoading = false;
    },
    FILES_LOADING(state: IDownloadsState): void {
      state.filesLoadError = null;
      state.filesLoading = true;
    },
    FILES_LOADED(state: IDownloadsState, payload: FileDto[]): void {
      state.files = payload;
      state.filesLoading = false;
    },
    FILES_LOAD_ERROR(state: IDownloadsState, error: Error): void {
      state.filesLoadError = getErrorMessage(error);
      state.filesLoading = false;
    },
    SELECT_RELEASE(state: IDownloadsState, release: FilesItem): void {
      state.selectedRelease = release;
    },
    RESET_STATE(state: IDownloadsState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadReleases({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('RESET_STATE');
        commit('RELEASES_LOADING');
        const organizationId = rootGetters['organizations/organizationId'];
        const accessToken = rootGetters['login/accessToken'];
        const response = await getDownloadsApi(accessToken).downloadsControllerFindUserReleases(organizationId);
        commit('RELEASES_LOADED', response.data);
      } catch (error) {
        commit('RELEASES_LOAD_ERROR', error);
        logger.captureStoreError('loadReleases', error);
      }
    },

    async loadFiles({ commit, rootGetters }: ActionParams, releaseId: number): Promise<void> {
      try {
        commit('FILES_LOADING');
        const organizationId = rootGetters['organizations/organizationId'];
        const accessToken = rootGetters['login/accessToken'];
        const response = await getDownloadsApi(accessToken).downloadsControllerFindFilesByRelease(
          releaseId,
          organizationId,
        );
        commit('FILES_LOADED', response.data);
      } catch (error) {
        commit('FILES_LOAD_ERROR', error);
        logger.captureStoreError('loadFiles', error, { releaseId });
      }
    },

    async downloadFile({ rootGetters }: ActionParams, id: number): Promise<void> {
      const organizationId = rootGetters['organizations/organizationId'];
      const accessToken = rootGetters['login/accessToken'];
      const response = await getDownloadsApi(accessToken).downloadsControllerGetFileDownloadUrl(id, organizationId);
      const url = response.data;
      downloadFileUsingUrl(url);
    },

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    releases: (state: IDownloadsState): ReleaseForDownloadsDto[] => state.releases,
    files: (state: IDownloadsState): FileDto[] => state.files,
    releasesLoading: (state: IDownloadsState): boolean => state.releasesLoading,
    releasesLoadError: (state: IDownloadsState): string | null => state.releasesLoadError,
    filesLoading: (state: IDownloadsState): boolean => state.filesLoading,
    filesLoadError: (state: IDownloadsState): string | null => state.filesLoadError,
    selectedRelease: (state: IDownloadsState): FilesItem | null => state.selectedRelease,
  },
};
