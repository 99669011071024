/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import {
  adminCloudProducts,
  adminDemos,
  adminDiscounts,
  adminDongles,
  adminInventory,
  adminOrders,
  adminOrganizations,
  adminPendingUsers,
  adminReleases,
  adminUploads,
  adminUsers,
  cloudProducts,
  customerDownloads,
  demo,
  dictionary,
  dongles,
  feedback,
  invoices,
  login,
  organizations,
  shop,
  snackbar,
  support,
} from './modules';
import { initialState } from '@/store/initialState/initialState';
import { APP_MUTATIONS } from '@/store/mutations/mutations';
import { APP_ACTIONS } from '@/store/actions/actions';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

// noinspection ES6ShorthandObjectProperty
export default new Vuex.Store({
  state: initialState,
  mutations: APP_MUTATIONS,
  actions: APP_ACTIONS,
  getters: {},
  modules: {
    // @ts-ignore
    adminDemos,
    // @ts-ignore
    adminDiscounts,
    // @ts-ignore
    adminDongles,
    // @ts-ignore
    adminInventory,
    // @ts-ignore
    adminOrganizations,
    // @ts-ignore
    adminOrders,
    // @ts-ignore
    adminUploads,
    // @ts-ignore
    adminReleases,
    // @ts-ignore
    adminUsers,
    // @ts-ignore
    adminCloudProducts,
    // @ts-ignore
    login,
    // @ts-ignore
    shop: shop,
    // @ts-ignore
    dictionary,
    // @ts-ignore
    invoices,
    // @ts-ignore
    organizations,
    // @ts-ignore
    customerDownloads,
    // @ts-ignore
    demo: demo,
    // @ts-ignore
    dongles,
    // @ts-ignore
    snackbar,
    // @ts-ignore
    feedback,
    // @ts-ignore
    cloudProducts,
    // @ts-ignore
    support,
    // @ts-ignore
    adminPendingUsers,
  },
  plugins: [
    //createLogger(),
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
});
