import OrderState, { Getters, IOrderState } from '@/store/modules/common/OrderState';
import { getErrorMessage, getOrdersApi, getProductsApi, getStripeApi, ISearchParams } from '@/castapi/helpers';
import { InvoiceDto, OrderSearchResponseDto, OrganizationWithOwnerDto, ProductDto } from '@/castapi';
import { AppLogger } from '@/logger';
import { Dummy } from '@/shared/types/common';
import { getInvoicesApi } from '../../../tests/api/helpers/api';
import { IActionParams } from '@/store/modules/index';

const order = new OrderState();

const logger = new AppLogger('adminOrders state');

interface IAdminOrderState extends IOrderState {
  orders: OrderSearchResponseDto[];
  ordersLoading: boolean;
  ordersLoadError: null | string;
  invoices: InvoiceDto[];
}

const initialState = (): IAdminOrderState => ({
  ...order.state(),
  orders: [],
  ordersLoading: false,
  ordersLoadError: null,
  productsMap: {},
  productLoadError: null,
  productsLoading: false,
  shopProducts: [],
  invoices: [],
});

type ActionParams = IActionParams & { state: IAdminOrderState; getters: Getters };

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    ...order.mutations,

    RESET_STATE(state: IAdminOrderState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    ORDERS_LOADING(state: IAdminOrderState): void {
      state.ordersLoading = true;
      state.ordersLoadError = null;
    },
    ORDERS_LOADED(state: IAdminOrderState, orders: OrderSearchResponseDto[]): void {
      state.orders = orders;
      state.ordersLoading = false;
    },
    ORDERS_LOAD_ERROR(state: IAdminOrderState, payload: Error): void {
      state.ordersLoading = false;
      state.ordersLoadError = getErrorMessage(payload);
    },
    SET_RENEW_UPGRADE_PRODUCTS_FOR_FULFILLED_ORDER(state: IAdminOrderState, payload: ProductDto[]): void {
      state.productsLoading = false;
      state.renewUpgrade = {
        ...initialState().renewUpgrade,
        renewProducts: payload,
      };
    },
    INVOICE_SENT(state: IAdminOrderState): void {
      state.isLoading = false;
    },
    INVOICE_VOIDED(state: IAdminOrderState): void {
      state.isLoading = false;
    },
    INVOICES_LOADED(state: IAdminOrderState, invoices: InvoiceDto[]): void {
      state.isLoading = false;
      state.invoices = [...invoices];
    },
  },
  actions: {
    ...order.actions,

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    async loadOrders(
      { commit, rootGetters }: ActionParams,
      payload: ISearchParams & {
        fulfilled?: boolean;
        refunded?: boolean;
        notPaid?: boolean;
        invoiceSent?: boolean;
      },
    ): Promise<void> {
      const { limit, offset, searchText, sortBy, sortDesc, fulfilled, refunded, notPaid, invoiceSent } = payload || {};
      commit('ORDERS_LOADING');
      try {
        const response = await getOrdersApi(rootGetters['login/accessToken']).ordersControllerGetAllOrders(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
          fulfilled,
          refunded,
          notPaid,
          invoiceSent,
        );
        commit('ORDERS_LOADED', response.data);
      } catch (error) {
        commit('ORDERS_LOAD_ERROR', error);
        logger.captureStoreError('loadOrders', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },

    async getUserUnfinishedOrder(
      { commit, dispatch, getters, rootGetters }: ActionParams,
      userId: number,
    ): Promise<void> {
      try {
        if (!userId) {
          return;
        }
        const token = rootGetters['login/accessToken'];
        if (!token || getters.cartProducts?.length) {
          return;
        }
        if (!getters.allProducts?.length) {
          await dispatch('getProductsFromApi', true);
          return;
        }
        commit('LOADING');
        const response = await getOrdersApi(token).ordersControllerGetUserUnfinishedOrder(userId);
        await dispatch('orderLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getUserUnfinishedOrder', error, { userId });
      }
    },

    async getRenewUpgradeProductForFulfilledOrder(
      { commit, rootGetters }: ActionParams,
      productId: number,
    ): Promise<void> {
      commit('PRODUCTS_LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        const response = await getProductsApi(token).productsControllerFindOne(productId);
        commit('SET_RENEW_UPGRADE_PRODUCTS_FOR_FULFILLED_ORDER', [response.data]);
      } catch (error) {
        commit('PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('getRenewUpgradeProductForFulfilledOrder', error, { productId });
      }
    },

    async sendInvoice({ commit, getters, rootGetters }: ActionParams): Promise<void> {
      const orderId = getters.orderId;
      if (!orderId) {
        return;
      }
      commit('LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        await getStripeApi(token).stripeControllerSendStripeInvoiceForOrder({ orderId });
        commit('INVOICE_SENT');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('sendInvoice', error);
      }
    },

    async voidInvoice({ commit, getters, rootGetters }: ActionParams): Promise<void> {
      const orderId = getters.orderId;
      if (!orderId) {
        return;
      }
      commit('LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        await getStripeApi(token).stripeControllerVoidStripeInvoiceForOrder({ orderId });
        commit('INVOICE_VOIDED');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('voidInvoice', error);
      }
    },

    async sendCastInvoiceToPay({ commit, getters, rootGetters }: ActionParams): Promise<void> {
      const orderId = getters.orderId;
      if (!orderId) {
        return;
      }
      commit('LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        await getOrdersApi(token).ordersControllerSendCastInvoiceToPay({ orderId });
        commit('INVOICE_SENT');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('sendCastInvoiceToPay', error);
      }
    },

    async getOrderInvoices({ commit, getters, rootGetters }: ActionParams): Promise<void> {
      const orderId = getters.orderId;
      if (!orderId) {
        return;
      }
      commit('LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        const response = await getInvoicesApi(token).invoicesControllerGetOrderInvoices(orderId);
        commit('INVOICES_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getOrderInvoices', error);
      }
    },
  },
  getters: {
    ...order.getters,
    orders: (state: IAdminOrderState): OrderSearchResponseDto[] => state.orders,
    ordersLoading: (state: IAdminOrderState): boolean => state.ordersLoading,
    ordersLoadError: (state: IAdminOrderState): string | null => state.ordersLoadError,
    orderCustomer: (
      state: IAdminOrderState,
      _getters: Dummy,
      _rootState: Dummy,
      rootGetters: {
        'adminOrganizations/organizations': OrganizationWithOwnerDto[];
      },
    ): OrganizationWithOwnerDto | undefined =>
      rootGetters['adminOrganizations/organizations'].find(
        organization => organization.organizationId === state.order?.organizationRef,
      ),
    isCloudOrderValidToFulfil: (state: IAdminOrderState): boolean | undefined =>
      state.order?.isCloud ? state.order?.cloudLicenseCreated : true,
    invoices: (state: IAdminOrderState): InvoiceDto[] => state.invoices,
  },
};
