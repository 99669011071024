<template>
  <v-app id="app">
    <Header :key="$route.fullPath" />
    <Snackbar />
    <v-main>
      <v-container class="main-container">
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
    <product-fruits
      v-if="isProductFruitsOn && user"
      project-code="ocblOqrTQ6B2jMXZ"
      language="en"
      :username="user.firstName + ' ' + user.lastName"
      :email="user.email"
      :firstname="user.firstName"
      :lastname="user.lastName"
      :sign-up-at="user.lastLoginDate"
      :role="role"
    />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/shared/various/Header.vue';
import Snackbar from '@/components/shared/various/Snackbar.vue';
import { UserDto } from '@/castapi';
import ProductFruits from '@/components/shared/integrations/productFruits';

@Component({
  components: {
    ProductFruits,
    Header,
    Snackbar,
  },
})
export default class App extends Vue {
  async created(): Promise<void> {
    if (this.$store.getters['login/loggedIn']) {
      await this.$store.dispatch('login/RELOAD_CURRENT_USER_DATA');
    }
  }

  get user(): UserDto {
    return this.$store.getters['login/user'];
  }

  get role(): string {
    return this.$store.getters['login/roleName'];
  }

  get isProductFruitsOn(): boolean {
    const urlsWithProductFruits = ['app.cast-soft.com' /*, 'develop.id.cast-soft.com'*/ /*, 'localhost'*/];
    return urlsWithProductFruits.some(url => window.location.href.includes(url));
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');

.main-container {
  max-width: 1440px !important;
  height: 100%;
  min-height: calc(100vh - 64px);
}
</style>
