import { getDonglesApi, getErrorMessage, isKnownError } from '@/castapi/helpers';
import {
  DongleActivityDto,
  DongleLicenseDto,
  HttpExceptionDto,
  InsertDongleByOwnerDto,
  ProductInfoDto,
} from '@/castapi';
import { AppLogger } from '@/logger';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('dongles state');

interface IDonglesState {
  dongles: ProductInfoDto[];
  donglesLoading: boolean;
  donglesLoadError: null | string;
  currentDongle: null | (ProductInfoDto & { emergencyCode?: string });
  currentDongleChanging: boolean;
  currentDongleChangeError: null | string;
  isRenewUpgrade: boolean;
  dongleInserting: boolean;
  dongleInsertError: null | string;
  dongleActivitiesLoading: boolean;
  dongleActivitiesLoadError: null | string;
  dongleActivities: DongleActivityDto[];
  dongleLicensesLoading: boolean;
  dongleLicensesLoadError: null | string;
  dongleLicenses: DongleLicenseDto[];
  dongleReauthCodeGenerating: boolean;
  dongleReauthCodeError: null | string;
  dongleReauthCode: null | string;
  dongleEmergencyCodeGenerating: boolean;
  dongleEmergencyCodeError: null | string;
}

const initialState: () => IDonglesState = () => ({
  dongles: [],
  donglesLoading: false,
  donglesLoadError: null,
  currentDongle: null,
  currentDongleChanging: false,
  currentDongleChangeError: null,
  isRenewUpgrade: true,
  dongleInserting: false,
  dongleInsertError: null,
  dongleActivitiesLoading: false,
  dongleActivitiesLoadError: null,
  dongleActivities: [],
  dongleReauthCodeGenerating: false,
  dongleReauthCodeError: null,
  dongleReauthCode: null,
  dongleEmergencyCodeGenerating: false,
  dongleEmergencyCodeError: null,
  dongleLicenses: [],
  dongleLicensesLoading: false,
  dongleLicensesLoadError: null,
});

type ActionParams = IActionParams & { state: IDonglesState };

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    DONGLES_LOADING(state: IDonglesState): void {
      state.donglesLoadError = null;
      state.donglesLoading = true;
    },

    DONGLES_LOADED(state: IDonglesState, payload: ProductInfoDto[]): void {
      state.dongles = payload;
      state.donglesLoading = false;
      state.currentDongle = state.dongles.find(dongle => dongle.dongleId === state.currentDongle?.dongleId) || null;
    },

    DONGLES_LOAD_ERROR(state: IDonglesState, payload: Error): void {
      state.donglesLoadError = getErrorMessage(payload);
      state.donglesLoading = false;
    },

    DONGLE_CHANGING(state: IDonglesState): void {
      state.currentDongleChanging = true;
      state.currentDongleChangeError = null;
    },

    DONGLE_CHANGED(state: IDonglesState): void {
      state.currentDongleChanging = false;
    },

    DONGLE_CHANGE_ERROR(state: IDonglesState, payload: Error): void {
      state.currentDongleChangeError = getErrorMessage(payload);
      state.currentDongleChanging = false;
    },

    SELECT_DONGLE(state: IDonglesState, dongle: ProductInfoDto): void {
      state.currentDongle = dongle;
    },

    DONGLE_INSERTING(state: IDonglesState): void {
      state.dongleInserting = true;
      state.dongleInsertError = null;
    },

    DONGLE_INSERTED(state: IDonglesState): void {
      state.dongleInserting = false;
    },

    DONGLE_INSERT_ERROR(state: IDonglesState, payload: Error): void {
      state.dongleInsertError = getErrorMessage(payload);
      state.dongleInserting = false;
    },

    DONGLE_ACTIVITIES_LOADING(state: IDonglesState): void {
      state.dongleActivities = [];
      state.dongleActivitiesLoadError = null;
      state.dongleActivitiesLoading = true;
    },

    DONGLE_ACTIVITIES_LOADED(state: IDonglesState, activities: DongleActivityDto[]): void {
      state.dongleActivities = activities;
      state.dongleActivitiesLoading = false;
    },

    DONGLE_ACTIVITIES_LOAD_ERROR(state: IDonglesState, payload: Error): void {
      state.dongleActivitiesLoading = false;
      state.dongleActivitiesLoadError = getErrorMessage(payload);
    },

    DONGLE_LICENSES_LOADING(state: IDonglesState): void {
      state.dongleLicenses = [];
      state.dongleLicensesLoadError = null;
      state.dongleLicensesLoading = true;
    },

    DONGLE_LICENSES_LOADED(state: IDonglesState, activities: DongleLicenseDto[]): void {
      state.dongleLicenses = activities;
      state.dongleLicensesLoading = false;
    },

    DONGLE_LICENSES_LOAD_ERROR(state: IDonglesState, payload: Error): void {
      state.dongleLicensesLoading = false;
      state.dongleLicensesLoadError = getErrorMessage(payload);
    },

    DONGLE_REAUTH_CODE_GENERATING(state: IDonglesState): void {
      state.dongleReauthCodeGenerating = true;
      state.dongleReauthCodeError = null;
      state.dongleReauthCode = null;
    },

    DONGLE_REAUTH_CODE_GENERATED(state: IDonglesState, code: string): void {
      state.dongleReauthCodeGenerating = false;
      state.dongleReauthCode = code;
    },

    DONGLE_REAUTH_CODE_ERROR(state: IDonglesState, payload: Error): void {
      state.dongleReauthCodeGenerating = false;
      state.dongleReauthCodeError = getErrorMessage(payload);
    },

    DROP_DONGLE_REAUTH_CODE(state: IDonglesState): void {
      state.dongleReauthCodeGenerating = false;
      state.dongleReauthCodeError = null;
      state.dongleReauthCode = null;
    },

    DONGLE_EMERGENCY_CODE_GENERATING(state: IDonglesState): void {
      state.dongleEmergencyCodeGenerating = true;
      state.dongleEmergencyCodeError = null;
    },

    DONGLE_EMERGENCY_CODE_GENERATED(state: IDonglesState, emergencyCode: string): void {
      state.dongleEmergencyCodeGenerating = false;
      state.currentDongle = { ...(state.currentDongle as ProductInfoDto), emergencyCode };
    },

    DONGLE_EMERGENCY_CODE_ERROR(state: IDonglesState, payload: Error): void {
      state.dongleEmergencyCodeGenerating = false;
      state.dongleEmergencyCodeError = getErrorMessage(payload);
    },

    RESET_STATE(state: IDonglesState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadDongles({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByOrganization(
          rootGetters['organizations/organizationId'],
        );
        commit('DONGLES_LOADED', response.data);
      } catch (error) {
        commit('DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error);
      }
    },

    async insertDongle({ commit, dispatch, rootGetters }: ActionParams, data: InsertDongleByOwnerDto): Promise<void> {
      commit('DONGLE_INSERTING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerInsertDongleByOwner(data);
        commit('DONGLE_INSERTED');
        await dispatch('loadDongles');
      } catch (error) {
        commit('DONGLE_INSERT_ERROR', error);
        logger.captureStoreError('insertDongle', error, { data });
      }
    },

    async assignDongle(
      { commit, dispatch, rootGetters }: ActionParams,
      { dongleId, userId }: { dongleId: number; userId: number },
    ): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerAssignDongle({ dongleId, userId });
        commit('DONGLE_CHANGED');
        await dispatch('loadDongles');
      } catch (error) {
        commit('DONGLE_CHANGE_ERROR', error);
        logger.captureStoreError('assignDongle', error, { dongleId, userId });
      }
    },

    async unAssignDongle({ commit, dispatch, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerUnAssignDongle({ dongleId });
        commit('DONGLE_CHANGED');
        await dispatch('loadDongles');
      } catch (error) {
        commit('DONGLE_CHANGE_ERROR', error);
        logger.captureStoreError('unAssignDongle', error, { dongleId });
      }
    },

    async loadDongleActivities({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_ACTIVITIES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetDongleActivities(
          dongleId,
        );
        commit('DONGLE_ACTIVITIES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_ACTIVITIES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleActivities', error, { dongleId });
      }
    },

    async loadDongleLicenses({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_LICENSES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetLearnDongleLicenses(
          dongleId,
        );
        commit('DONGLE_LICENSES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_LICENSES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleLicenses', error, { dongleId });
      }
    },

    async generateDongleReauthCode({ commit }: ActionParams, dongleSerialCode: string): Promise<void> {
      commit('DONGLE_REAUTH_CODE_GENERATING');
      try {
        const response = await getDonglesApi().donglesControllerGetDongleReauthCode(dongleSerialCode);
        commit('DONGLE_REAUTH_CODE_GENERATED', response.data);
      } catch (error) {
        commit('DONGLE_REAUTH_CODE_ERROR', error);
        if (isKnownError(error as HttpExceptionDto, 400, 'Dongle not assigned to any user')) {
          return;
        }
        logger.captureStoreError('generateDongleReauthCode', error, { dongleSerialCode });
      }
    },

    async generateDongleEmergencyCode({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      commit('DONGLE_EMERGENCY_CODE_GENERATING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetDongleEmergencyCode(
          dongleId,
        );
        commit('DONGLE_EMERGENCY_CODE_GENERATED', response.data);
      } catch (error) {
        commit('DONGLE_EMERGENCY_CODE_ERROR', error);
        logger.captureStoreError('generateDongleEmergencyCode', error, { dongleId });
      }
    },

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    dongles: (state: IDonglesState): ProductInfoDto[] => state.dongles,
    donglesLoading: (state: IDonglesState): boolean => state.donglesLoading,
    donglesLoadError: (state: IDonglesState): string | null => state.donglesLoadError,
    currentDongle: (state: IDonglesState): (ProductInfoDto & { emergencyCode?: string | undefined }) | null =>
      state.currentDongle,
    currentDongleSerialCode: (state: IDonglesState): string | undefined => state.currentDongle?.serialCode,
    currentDongleId: (state: IDonglesState): number | undefined => state.currentDongle?.dongleId,
    // dongleInserting: (state: IDonglesState) => state.dongleInserting,
    dongleInsertError: (state: IDonglesState): string | null => state.dongleInsertError,
    dongleActivities: (state: IDonglesState): DongleActivityDto[] => state.dongleActivities,
    dongleActivitiesLoading: (state: IDonglesState): boolean => state.dongleActivitiesLoading,
    dongleActivitiesLoadError: (state: IDonglesState): string | null => state.dongleActivitiesLoadError,
    dongleReauthCodeGenerating: (state: IDonglesState): boolean => state.dongleReauthCodeGenerating,
    dongleReauthCodeError: (state: IDonglesState): string | null => state.dongleReauthCodeError,
    dongleReauthCode: (state: IDonglesState): string | null => state.dongleReauthCode,
    dongleEmergencyCodeGenerating: (state: IDonglesState): boolean => state.dongleEmergencyCodeGenerating,
    dongleEmergencyCodeError: (state: IDonglesState): string | null => state.dongleEmergencyCodeError,
  },
};
