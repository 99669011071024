import {
  AddressDto,
  BucketApiFactory,
  Configuration,
  CreateAccountDto,
  InvoiceDto,
  InvoicesApiFactory,
  OrganizationDto,
  OrganizationExtendedDto,
  OrganizationsApiFactory,
  OrganizationWithOwnerDto,
  UserDto,
  UserRoleBindingDto,
  UserRoleDto,
} from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { uploadImage } from '@/store/modules/common/Files';
import { AppLogger } from '@/logger';
import { getErrorMessage, ISearchParams } from '@/castapi/helpers';
import { Dummy } from '@/shared/types/common';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminOrganizations state');

interface IAdminOrganizationsState {
  organizations: OrganizationWithOwnerDto[];
  organizationsLoading: boolean;
  organizationsLoadError: null | string;
  currentOrganizationData: OrganizationExtendedDto | null;
  currentOrganizationLoading: boolean;
  currentOrganizationLoadError: null | string;
  invoicesList: InvoiceDto[];
  invoicesLoading: boolean;
  invoicesLoadError: null | string;
  organizationUpdating: number | null;
  organizationUpdateError: null | string;
  accountCreating: boolean;
  accountCreationError: null | string;
}

const initialState = (): IAdminOrganizationsState => ({
  organizations: [],
  organizationsLoading: false,
  organizationsLoadError: null,
  currentOrganizationData: null,
  currentOrganizationLoading: false,
  currentOrganizationLoadError: null,
  invoicesList: [],
  invoicesLoading: false,
  invoicesLoadError: null,
  organizationUpdating: null,
  organizationUpdateError: null,
  accountCreating: false,
  accountCreationError: null,
});

const getOrganizationsApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return OrganizationsApiFactory(config);
};

const getInvoicesApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return InvoicesApiFactory(config);
};

const getBucketApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return BucketApiFactory(config);
};

interface Getters {
  currentOrganization: OrganizationDto | undefined;
  currentOrganizationAddresses: AddressDto[];
  currentOrganizationMainUserRole: UserRoleBindingDto & UserRoleDto;
}

type ActionParams = IActionParams & { state: IAdminOrganizationsState; getters: Getters };

// noinspection JSUnusedGlobalSymbols
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    ORGANIZATIONS_LOADING(state: IAdminOrganizationsState): void {
      state.organizationsLoading = true;
      state.organizationsLoadError = null;
    },
    ORGANIZATIONS_LOADED(state: IAdminOrganizationsState, organizations: OrganizationWithOwnerDto[]): void {
      state.organizations = organizations;
      state.organizationsLoading = false;
    },
    ORGANIZATIONS_LOAD_ERROR(state: IAdminOrganizationsState, payload: Error): void {
      state.organizationsLoading = false;
      state.organizationsLoadError = getErrorMessage(payload);
    },
    CURRENT_ORGANIZATION_LOADING(state: IAdminOrganizationsState): void {
      state.currentOrganizationData = null;
      state.currentOrganizationLoading = true;
      state.currentOrganizationData = null;
      state.currentOrganizationLoadError = null;
    },
    CURRENT_ORGANIZATION_LOADED(state: IAdminOrganizationsState, payload: OrganizationExtendedDto): void {
      state.currentOrganizationData = payload;
      state.currentOrganizationLoading = false;
    },
    CURRENT_LOAD_ERROR(state: IAdminOrganizationsState, payload: Error): void {
      state.currentOrganizationLoadError = getErrorMessage(payload);
      state.currentOrganizationLoading = false;
    },
    ORGANIZATION_UPDATING(state: IAdminOrganizationsState, organizationId: number): void {
      state.organizationUpdating = organizationId;
      state.organizationUpdateError = null;
    },
    ORGANIZATION_UPDATED(state: IAdminOrganizationsState): void {
      state.organizationUpdating = null;
    },
    ORGANIZATION_UPDATE_ERROR(state: IAdminOrganizationsState, payload: Error): void {
      state.organizationUpdateError = getErrorMessage(payload);
      state.organizationUpdating = null;
    },
    ORGANIZATION_DELETED(state: IAdminOrganizationsState): void {
      state.organizationUpdating = null;
    },
    INVOICES_LOADING(state: IAdminOrganizationsState): void {
      state.invoicesLoading = true;
      state.invoicesLoadError = null;
    },
    INVOICES_LOAD_ERROR(state: IAdminOrganizationsState, payload: Error): void {
      state.invoicesLoading = false;
      state.invoicesLoadError = getErrorMessage(payload);
    },
    INVOICES_LOADED(state: IAdminOrganizationsState, invoices: InvoiceDto[]): void {
      state.invoicesLoading = false;
      state.invoicesList = invoices;
    },
    ACCOUNT_CREATING(state: IAdminOrganizationsState): void {
      state.accountCreationError = null;
      state.accountCreating = true;
    },
    ACCOUNT_CREATED(state: IAdminOrganizationsState): void {
      state.accountCreating = false;
    },
    ACCOUNT_CREATION_ERROR(state: IAdminOrganizationsState, payload: Error): void {
      state.accountCreating = false;
      state.accountCreationError = getErrorMessage(payload);
    },
    RESET_STATE(state: IAdminOrganizationsState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    RESET_EDITOR_STATE(state: IAdminOrganizationsState): void {
      state.currentOrganizationData = null;
      state.currentOrganizationLoading = false;
      state.currentOrganizationLoadError = null;
      state.invoicesList = [];
      state.invoicesLoading = false;
      state.invoicesLoadError = null;
      state.organizationUpdating = null;
      state.organizationUpdateError = null;
      state.accountCreating = false;
      state.accountCreationError = null;
    },
    CLEAR_ORGANIZATION(state: IAdminOrganizationsState): void {
      state.currentOrganizationData = null;
    },
  },
  actions: {
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    resetEditorState({ commit }: ActionParams): void {
      commit('RESET_EDITOR_STATE');
    },
    async loadOrganizations({ commit, rootGetters }: ActionParams, searchParams: ISearchParams): Promise<void> {
      const { limit, offset, searchText, sortBy, sortDesc } = searchParams || {};
      commit('ORGANIZATIONS_LOADING', { limit, offset, searchText });
      try {
        const response = await getOrganizationsApi(
          rootGetters['login/accessToken'],
        ).organizationsControllerGetAllOrganizations(limit, offset, searchText, sortBy, sortDesc);
        commit('ORGANIZATIONS_LOADED', response.data);
      } catch (error) {
        commit('ORGANIZATIONS_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizations', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },

    async getOrganizationData({ commit, rootGetters }: ActionParams, organizationId: number): Promise<void> {
      commit('CURRENT_ORGANIZATION_LOADING');
      try {
        const response = await getOrganizationsApi(
          rootGetters['login/accessToken'],
        ).organizationsControllerGetOrganizationWithUserRoles(organizationId);
        commit('CURRENT_ORGANIZATION_LOADED', response.data);
      } catch (error) {
        commit('CURRENT_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizations', error, { organizationId });
      }
    },

    async updateOrganization(
      { commit, dispatch, rootGetters }: ActionParams,
      { organizationData, avatarImage }: { organizationData: OrganizationDto; avatarImage: string },
    ): Promise<void> {
      commit('ORGANIZATION_UPDATING', organizationData.organizationId);
      try {
        const organizationAvatar = avatarImage
          ? await uploadImage(
              avatarImage,
              `organization-avatars/${organizationData.organizationId}`,
              getBucketApi(rootGetters['login/accessToken']),
            )
          : null;
        let updateBody = { ...organizationData };
        if (avatarImage !== undefined) {
          updateBody = { ...updateBody, organizationAvatar: organizationAvatar || '' };
        }
        await getOrganizationsApi(rootGetters['login/accessToken']).organizationsControllerUpdateOrganization(
          updateBody,
        );
        commit('ORGANIZATION_UPDATED');
        await dispatch('getOrganizationData', organizationData.organizationId);
      } catch (error) {
        commit('ORGANIZATION_UPDATE_ERROR', error);
        logger.captureStoreError('updateOrganization', error, { organizationData, avatarImage });
      }
    },

    async loadInvoices({ commit, getters, rootGetters }: ActionParams): Promise<void> {
      commit('INVOICES_LOADING');
      try {
        if (!getters.currentOrganization) {
          throw new Error('Organization is not selected');
        }
        const response = await getInvoicesApi(
          rootGetters['login/accessToken'],
        ).invoicesControllerGetOrganizationInvoices(getters.currentOrganization.organizationId);
        commit('INVOICES_LOADED', response.data);
      } catch (error) {
        commit('INVOICES_LOAD_ERROR', error);
        logger.captureStoreError('loadInvoices', error, {
          organizationId: getters.currentOrganization?.organizationId,
        });
      }
    },

    async deleteOrganization({ commit, rootGetters }: ActionParams, organizationId: number): Promise<void> {
      commit('ORGANIZATION_UPDATING', organizationId);
      try {
        await getOrganizationsApi(rootGetters['login/accessToken']).organizationsControllerDeleteOrganization({
          organizationId,
        });
        commit('ORGANIZATION_DELETED');
      } catch (error) {
        commit('ORGANIZATION_UPDATE_ERROR', error);
        logger.captureStoreError('deleteOrganization', error, { organizationId });
      }
    },

    async createAccount({ commit, rootGetters }: ActionParams, createAccountDto: CreateAccountDto): Promise<void> {
      commit('ACCOUNT_CREATING');
      try {
        const response = await getOrganizationsApi(
          rootGetters['login/accessToken'],
        ).organizationsControllerCreateAccountByAdmin(createAccountDto);
        // `response.data` (organizationId) is using in mutation, but not in action
        commit('ACCOUNT_CREATED', response.data);
      } catch (error) {
        commit('ACCOUNT_CREATION_ERROR', error);
        logger.captureStoreError('createAccount', error);
      }
    },
  },
  getters: {
    organizations: (state: IAdminOrganizationsState): OrganizationWithOwnerDto[] => state.organizations,
    organizationsLoading: (state: IAdminOrganizationsState): boolean => state.organizationsLoading,
    organizationsLoadError: (state: IAdminOrganizationsState): string | null => state.organizationsLoadError,
    currentOrganizationData: (state: IAdminOrganizationsState): OrganizationExtendedDto | null =>
      state.currentOrganizationData,
    currentOrganization: (state: IAdminOrganizationsState): OrganizationDto | undefined =>
      state.currentOrganizationData?.organization,
    currentOrganizationId: (state: IAdminOrganizationsState): number | undefined =>
      state.currentOrganizationData?.organization.organizationId,
    currentOrganizationUsers: (state: IAdminOrganizationsState): UserDto[] =>
      state.currentOrganizationData?.users || [],
    currentOrganizationMembers: (state: IAdminOrganizationsState): UserDto[] =>
      state.currentOrganizationData?.users?.filter(
        u => state.currentOrganizationData?.rolesBinding?.find(rb => rb.userRef === u.userId)?.userRoleRef === 3,
      ) || [],
    currentOrganizationMainUserRole: (
      state: IAdminOrganizationsState,
      _getters: Getters,
      _rootState: Dummy,
      rootGetters: Dummy,
    ): (UserRoleBindingDto & UserRoleDto) | null => {
      let priority = 0;
      let mainUserRole = null;
      state.currentOrganizationData?.rolesBinding?.forEach(binding => {
        const roleType = rootGetters['dictionary/roleTypeGetter'](binding.userRoleRef);
        if (roleType.priority >= priority) {
          mainUserRole = { ...binding, ...roleType };
          priority = roleType.priority;
        }
      });
      return mainUserRole;
    },
    // TODO: Need to fix. A lot of cases when main user is not found. Perhaps, users or rolesBinding is not loaded
    currentOrganizationMainUser: (state: IAdminOrganizationsState, getters: Getters): UserDto => {
      const found = state.currentOrganizationData?.users?.find(
        (user: UserDto) => user.userId === getters.currentOrganizationMainUserRole?.userRef,
      );
      if (!found) {
        return {} as UserDto; // temp fix
        // throw new Error('Main user not found');
      }
      return found;
    },
    currentOrganizationLoading: (state: IAdminOrganizationsState): boolean => state.currentOrganizationLoading,
    currentOrganizationLoadError: (state: IAdminOrganizationsState): string | null =>
      state.currentOrganizationLoadError,
    currentOrganizationAddresses: (state: IAdminOrganizationsState): AddressDto[] | undefined =>
      state.currentOrganizationData?.addresses,
    currentOrganizationShippingAddresses: (_state: IAdminOrganizationsState, getters: Getters): AddressDto[] =>
      getters.currentOrganizationAddresses?.filter((address: AddressDto) => !address.isBilling) || [],
    currentOrganizationBillingAddresses: (_state: IAdminOrganizationsState, getters: Getters): AddressDto[] =>
      getters.currentOrganizationAddresses?.filter((address: AddressDto) => address.isBilling) || [],
    invoices: (state: IAdminOrganizationsState): InvoiceDto[] => state.invoicesList,
    invoicesLoading: (state: IAdminOrganizationsState): boolean => state.invoicesLoading,
    invoicesLoadError: (state: IAdminOrganizationsState): string | null => state.invoicesLoadError,
    // organizationUpdating: (state:IAdminOrganizationsState) => state.organizationUpdating,
    organizationUpdateError: (state: IAdminOrganizationsState): string | null => state.organizationUpdateError,
    accountCreationError: (state: IAdminOrganizationsState): string | null => state.accountCreationError,
    accountCreating: (state: IAdminOrganizationsState): boolean => state.accountCreating,
  },
};
